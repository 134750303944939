import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import VueApollo from 'vue-apollo'
import schema from './schema.json'
import VueCookies from 'vue-cookies'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: { __schema: schema.__schema }
})

const middlewareLinkPlus = setContext((_, { headers }) => {
  let auth = null
  const cookieNatif = document.cookie.split(';')
  const token = VueCookies.get('ClassDokomaToken') || ( cookieNatif.length > 0 && cookieNatif.find(e => e.includes('ClassDokomaToken')) ? cookieNatif.find(e => e.includes('ClassDokomaToken')).split('=')[1] : null )
  if (token) {
    auth = token?.includes('Bearer') ? token : 'Bearer ' + token
  }
  return {
    headers: {
      ...headers,
      'Accept-Language': 'FR-QC',
      Authorization: auth
    }
  }
})

const httpLinkV2 = new HttpLink({
  uri: 'https://api.dokoma.com/api/v1/graphql/salle_classe'
})

const cache = new InMemoryCache({ fragmentMatcher })
export const apolloClientV2 = new ApolloClient({
  link: middlewareLinkPlus.concat(httpLinkV2),
  cache,
  connectToDevTools: true
})

Vue.use(VueApollo)

export default new VueApollo({
  clients: {
    v2: apolloClientV2
  },
  defaultClient: apolloClientV2
})
