import { apolloClientV2 } from '@/setup/apollo.js'

const state = {
  dossierId: null,
  calendrierElementId: null,
  calendrierElementIdentifiant: null,
  activeGrid: null,
  gridOpacity: 0.25,
  gridColor: '#000000',
  tableauBgColor: '#ffffff',
  activePage: 1,
  pagesList: [], // deprecated: use App/CanvasAvailable instead
  valueForReplace: null,
  selection: null,
  loadingIndicator: 0,
  canvasZoom: 1,
  canvasPosX: 0,
  canvasPosY: 0,
  elementSelected: null,
  desmosTitle: 'Veuillez cliquer là où vous désirez insérer votre graph.'
}

const getters = {
  gridProperties (state) {
    const { activeGrid, gridOpacity, gridColor, tableauBgColor } = state
    return { activeGrid, gridOpacity, gridColor, tableauBgColor }
  },
  isTitleEditable (state) {
    return !!(state.dossierId && state.calendrierElementId)
  },
  activeLink (state, getters, rootState) {
    return rootState?.App?.canvasAvailable[state.activePage - 1]?.video
  }
}

const actions = {
  async initTableau ({ commit, dispatch, rootState }, tableauId) {
    try {
      const { data } = await apolloClientV2.query({
        query: require('@/apollo/queries/salleClasseEnregistrementTableauBlanc.gql'),
        variables: {
          id: tableauId
        },
        fetchPolicy: 'no-cache'
      })
      const tableauBlanc = data?.salleClasseEnregistrementTableauBlanc
      if (tableauBlanc) {
        // DossierId & CalendrierElementId are required to change the title
        commit('setDossierId', tableauBlanc?.dossier?.id)
        await dispatch('getCalendrierElementId', tableauBlanc?.dossier?.id)

        // Block edition interface if user is not authorized
        const profile = rootState?.User?.profile
        if (profile && tableauBlanc.dossier.usager.id !== profile.id) {
          commit('App/setViewerMode', true, { root: true })
        }

        // Dispatch tableau data in the store so that it can be rendered
        dispatch('App/initDataRef', tableauBlanc, { root: true })

        // Everything is ready, start rendering the tableau
        commit('App/setisCanvasReadyToBeShow', true, { root: true })
      }
    } catch (e) {
      if (e?.networkError?.statusCode === 403) {
        commit('User/setIsUnauthorized', true, { root: true })
      }
    }
  },
  async getCalendrierElementId ({ commit }, dossierId) {
    const { data } = await apolloClientV2.query({
      query: require('@/apollo/queries/calendrierCalendrierElementsMin.gql'),
      variables: {
        filtre: {
          contenus: { id: dossierId, type: 'DOSSIER' }
        }
      },
      fetchPolicy: 'no-cache'
    })
    const calendrierElementId = data?.calendrierCalendrierElements?.resultat?.[0]?.id
    commit('setCalendrierElementIdentifiant', data?.calendrierCalendrierElements?.resultat?.[0]?.identifiant)
    if (calendrierElementId) {
      commit('setCalendrierElementId', calendrierElementId)
    }
  },
  saveNewTitle ({ state, getters }, newTitle) {
    if (!getters['isTitleEditable']) {
      console.error('Cannot edit title because isTitleEditable is set to false')
    }
    // V2: Only call the updateCalendrierElement
    apolloClientV2.mutate({
      mutation: require('@/apollo/mutations/updateCalendrierElement.gql'),
      variables: {
        id: state.calendrierElementIdentifiant,
        descendance: true,
        attrs: { titre: newTitle }
      }
    }).then(({ data }) => {
      if (data?.errors) {
        console.error(data.errors)
      }
    })
    // V1: updateCalendrierElement for each CCE, updateEnregistrementDossier, updateEnregistrementTableauBlanc, ...
    // 1-3 Save CalendrierElement
    // apolloClientV2.mutate({
    //   mutation: require('@/apollo/mutations/updateCalendrierElement.gql'),
    //   variables: {
    //     id: state.calendrierElementId,
    //     attrs: { titre: newTitle }
    //   }
    // }).then(({ data }) => {
    //   if (data?.errors) {
    //     console.error(data.errors)
    //   }
    // })

    // // 2-3 Save EnregistrementDossier
    // apolloClientV2.mutate({
    //   mutation: require('@/apollo/mutations/updateSalleClasseEnregistrementDossier.gql'),
    //   variables: {
    //     id: state.dossierId,
    //     attrs: { titre: newTitle }
    //   }
    // }).then(({ data }) => {
    //   if (data?.errors) {
    //     console.error(data.errors)
    //   }
    // })

    // // 3-3 Save tableauBlanc
    // dispatch('App/saveCanvas', null, { root: true })
  },
  setGridProperties ({ commit }, { activeGrid, gridOpacity, gridColor, tableauBgColor }) {
    commit('setActiveGrid', activeGrid)
    commit('setGridOpacity', gridOpacity)
    commit('setGridColor', gridColor)
    commit('setTableauBgColor', tableauBgColor)
  }
}

const mutations = {
  setDesmosTitle (state, payload) {
    state.desmosTitle = payload
  },
  replaceSelectedElement (state, payload) {
    state.valueForReplace = payload
  },
  setDossierId (state, payload) {
    state.dossierId = payload
  },
  setCalendrierElementId (state, payload) {
    state.calendrierElementId = payload
  },
  setCalendrierElementIdentifiant (state, payload) {
    state.calendrierElementIdentifiant = payload
  },
  setSelection (state, payload) {
    state.selection = payload
  },
  setActiveGrid (state, payload) {
    state.activeGrid = payload
  },
  setGridOpacity (state, payload) {
    state.gridOpacity = payload
    document.documentElement.style.setProperty('--grid-opacity', payload)
  },
  setGridColor (state, payload) {
    state.gridColor = payload
    document.documentElement.style.setProperty('--grid-color', payload)
  },
  setTableauBgColor (state, payload) {
    state.tableauBgColor = payload
    document.documentElement.style.setProperty('--bg-color', payload)
  },
  setElementSelected (state, payload) {
    state.elementSelected = payload
  },
  setActivePage (state, payload) {
    state.activePage = payload
  },
  addIndicatorLoading (state) {
    state.loadingIndicator++
  },
  removeIndicatorLoading (state) {
    state.loadingIndicator--
    if (state.loadingIndicator < 0) {
      state.loadingIndicator = 0
    }
  },
  setCanvasZoom (state, newZoom) {
    if (state.canvasZoom !== newZoom) {
      state.canvasZoom = newZoom
    }
  },
  setCanvasPosition (state, { x, y }) {
    state.canvasPosX = x
    state.canvasPosY = y
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
